import { useCallback, useEffect, useRef, useState } from 'react';
import nullthrows from 'nullthrows';
import { useNavigate } from 'react-router-dom';
import { templatedString } from '@pointdotcom/pds';
import { Page, generateUrlFromPage } from 'containers/helpers';
import { useTaskCompletionModal } from 'containers/hooks/useTaskCompletionModal';
import { FeatureFlag, useFeatureFlag } from 'lib/featureFlags';
import { getUnderwriteFollowUpUrl } from 'services/underwrite';
import i18n from './i18n';
import {
  ApplicationProcessingOutcome,
  useApplicationProcessingOutcome,
} from './useApplicationProcessingOutcome';

type PromiseResolver<T> = (value: T | PromiseLike<T>) => void;

function promiseWithTimeout<T>(promise: PromiseLike<T>, timeoutMs: number): Promise<undefined | T> {
  return Promise.race([
    promise,
    new Promise<undefined>((resolve) => {
      setTimeout(() => resolve(undefined), timeoutMs);
    }),
  ]);
}

export function useHandleApplicationSubmitted({
  estimateKey,
}: {
  estimateKey: undefined | string;
}) {
  const resolveApplicationProcessingOutcomeRef =
    useRef<PromiseResolver<ApplicationProcessingOutcome>>();
  const [applicationProcessingOutcomePromise] = useState(
    () =>
      new Promise<ApplicationProcessingOutcome>((resolve) => {
        resolveApplicationProcessingOutcomeRef.current = resolve;
      })
  );
  const applicationProcessingOutcome = useApplicationProcessingOutcome();

  useEffect(() => {
    if (!applicationProcessingOutcome.isProcessing) {
      nullthrows(resolveApplicationProcessingOutcomeRef.current)(applicationProcessingOutcome);
    }
  }, [applicationProcessingOutcome]);

  const navigate = useNavigate();

  const postAppDocUploadFollowupFlagEnabled =
    useFeatureFlag(FeatureFlag.PostAppDocUploadFollowup) ?? false;

  const showTaskCompletionModal = useTaskCompletionModal();

  return useCallback(async () => {
    if (postAppDocUploadFollowupFlagEnabled) {
      const outcome = await promiseWithTimeout(applicationProcessingOutcomePromise, 60_000);
      if (outcome == null) {
        // If timed out, just redirect to dashboard:
        navigate(generateUrlFromPage(Page.DASHBOARD));
        return;
      }
      const { showDashboardModal, applicationTask, dashboard, navigateTo } = outcome;
      if (showDashboardModal) {
        // Show task completion modal
        const name = dashboard?.getContact().firstName;
        showTaskCompletionModal({
          task: applicationTask,
          title:
            name != null
              ? templatedString({ template: i18n.congratsWithName, values: { name } })
              : i18n.congrats,
          heading: i18n.applicationReceived,
        });
      }
      navigate(navigateTo ?? generateUrlFromPage(Page.DASHBOARD));
    } else {
      // Redirect to post-application document upload page on Underwrite
      if (estimateKey != null) {
        const underwriteFollowupUrl = getUnderwriteFollowUpUrl(estimateKey);
        window.location.assign(underwriteFollowupUrl);
        return;
      } else {
        navigate(generateUrlFromPage(Page.DASHBOARD));
      }
    }
  }, [
    applicationProcessingOutcomePromise,
    estimateKey,
    navigate,
    postAppDocUploadFollowupFlagEnabled,
    showTaskCompletionModal,
  ]);
}
