import React from 'react';
import Helmet from 'react-helmet';
import { useParams } from 'react-router';
import {
  Container,
  DirectionAndPlacement,
  Header,
  Logo,
  Size,
  TemplatedText,
  Validation,
  directory,
} from '@pointdotcom/pds';
import ConfettiBackground from 'components/ConfettiBackground';
import MainFooter from 'components/MainFooter';
import MainHeaderNoNav from 'components/MainHeaderNoNav';
import { generateUrlFromPage, pages } from 'containers/helpers';
import { usePointContact } from 'containers/prequal/hooks/usePointContact';
import { logWarning } from 'lib/logger';
import { Products } from 'store/constants';
import * as appStyles from 'styles/';
import { ErrorType } from './constants';
import i18n from './i18n';
import * as styles from './styles';

const { PointEmail, PointNumber } = directory;

interface ErrorPageTemplateProps {
  children?: React.ReactNode;
  confetti?: boolean;
  errorType?: ErrorType;
  headerText?: string;
  mainHeaderProps?: Record<string, unknown>;
  product?: Products;
  showMainHeader?: boolean;
  showFooter?: boolean;
  supportNumber?: string;
}

// Template must be used so that we can use a version without hooks.
// This is necessary since we render this page as an error boundary which
// is outside the the scope of a router. Many of our hooks use useParams,
// which will throw if used outside a router context

export const ErrorPageTemplate = ({
  children,
  confetti = false,
  errorType: errorTypeFromProps = ErrorType.General,
  headerText,
  mainHeaderProps = {
    nav: false,
    showSubHeader: false,
    bannerProps: {},
  },
  product,
  showMainHeader = true,
  showFooter = true,
  supportNumber = PointNumber.Support,
}: ErrorPageTemplateProps) => {
  let errorType = ErrorType.General;
  if (Object.values(ErrorType).includes(errorTypeFromProps)) {
    errorType = errorTypeFromProps;
  }

  const headerTextMap = {
    [ErrorType.FourOhFour]: i18n.pageNotFound,
    [ErrorType.General]: Validation.i18n.whoops,
    [ErrorType.ApplicationExpired]: i18n.previouslyStartedAnApplication,
    [ErrorType.ApplicationRejected]: i18n.unfortunately,
    [ErrorType.ApplicationClosed]: i18n.weveEnjoyed,
    [ErrorType.OfferExpired]: i18n.thankYou,
  };

  const subHeaderTextMap = {
    [ErrorType.FourOhFour]: Validation.i18n.callOrEmail,
    [ErrorType.General]: Validation.i18n.wereSorry,
    [ErrorType.ApplicationExpired]: i18n.ifYouHaveQuestions,
    [ErrorType.ApplicationRejected]: i18n.thankYouBestOfLuck,
    [ErrorType.ApplicationClosed]: i18n.ifYouNeed,
    [ErrorType.OfferExpired]: i18n.offerExpired,
  };

  const headerTextNew =
    headerText ||
    headerTextMap[errorType as keyof typeof headerTextMap] ||
    headerTextMap[ErrorType.General];
  const subHeaderText = subHeaderTextMap[errorType as keyof typeof subHeaderTextMap];

  const templateValues = {
    email: (
      <a href={`mailto: ${PointEmail.Support}`} key="email">
        {PointEmail.Support}
      </a>
    ),
    phone: (
      <a href={`tel: ${supportNumber}`} key="phone">
        {supportNumber}
      </a>
    ),
    prequal: (
      <a type="primary" href={generateUrlFromPage(pages.PREQUAL_DEFAULT)} key="prequal">
        {i18n.getANew}
      </a>
    ),
    createNewOffer: <a href={generateUrlFromPage(pages.PREQUAL_DEFAULT)}>{i18n.createNewOffer}</a>,
  };

  // Report instances of unexpected error pages
  if (errorType === ErrorType.General || errorType === ErrorType.FourOhFour) {
    logWarning({
      eventType: 'whoopsPage',
      detail: {
        errorType,
        location: window.location.href,
        message: `${errorType} failure at ${window.location?.pathname}`,
      },
    });
  }

  return (
    <appStyles.MainPageStyle className="ErrorPage">
      <Helmet title="Oops" />
      {showMainHeader && (
        <MainHeaderNoNav
          {...mainHeaderProps}
          bannerProps={{ ...(mainHeaderProps?.bannerProps || {}), hideBanner: true }}
        />
      )}
      <styles.HeroContainerStyle styleSize={Size.Large}>
        {confetti ? <ConfettiBackground run recycle /> : null}
        <Container>
          {!showMainHeader && <Logo href="/" />}
          <styles.ErrorPageInnerContainerStyle>
            <Header
              styleSize={Size.Splash}
              styleAlign={DirectionAndPlacement.Left}
              styleAlignMobile={DirectionAndPlacement.Left}
            >
              <TemplatedText values={templateValues}>{headerTextNew}</TemplatedText>
            </Header>
            <appStyles.SplashCopySerifStyle>
              {children ||
                (subHeaderText && (
                  <TemplatedText values={templateValues}>{subHeaderText}</TemplatedText>
                ))}
            </appStyles.SplashCopySerifStyle>
          </styles.ErrorPageInnerContainerStyle>
        </Container>
      </styles.HeroContainerStyle>
      {showFooter && <MainFooter product={product} />}
    </appStyles.MainPageStyle>
  );
};

const ErrorPage = (props: ErrorPageTemplateProps) => {
  const { product = Products.HEI } = props;
  const { supportNumber } = usePointContact({ product });
  const { errorType } = useParams();
  return (
    <ErrorPageTemplate
      errorType={errorType as ErrorType}
      {...props}
      supportNumber={supportNumber}
    />
  );
};

export { ErrorType };
export default ErrorPage;
