import React from 'react';
import PropTypes from 'prop-types';
import { Transition } from 'react-transition-group';
import { Icon, IconName, Key, Style, eventHasKey } from '@pointdotcom/pds';
import {
  Table2ColCellContentStyle,
  Table2ColCellStyle,
  Table2ColIEHacks,
  Table2ColRowStyle,
  Table2ColStyle,
  Table2ColTopWrapStyle,
  TableFoldingIconStyle,
  TableTopCellContainerStyle,
} from './styles';

class TableRow extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      unfolded: false,
    };
  }

  handleFoldUnfold = (e) => {
    const { foldingContent } = this.props;

    if (!foldingContent) {
      return;
    }

    e.stopPropagation();
    const { unfolded } = this.state;
    this.setState({ unfolded: !unfolded });
  };

  handleRowKeyDown = (e) => {
    const { foldingContent } = this.props;

    if (!foldingContent) {
      return;
    }

    if (eventHasKey(e, [Key.Enter, Key.Space])) {
      e.preventDefault();
      this.handleFoldUnfold(e);
    }
  };

  render() {
    const { foldingContent, size, tabIndex, label, value } = this.props;
    const { unfolded } = this.state;
    const foldable = !!foldingContent;
    return (
      <Table2ColRowStyle
        onClick={this.handleFoldUnfold}
        foldable={foldable}
        size={size}
        tabIndex={tabIndex}
        onKeyDown={this.handleRowKeyDown}
        role={foldable ? 'button' : null}
        aria-label={label}
      >
        {foldable && <Table2ColIEHacks />}
        <Table2ColTopWrapStyle>
          <TableTopCellContainerStyle foldable={foldable}>
            <Table2ColCellStyle size={size} foldable={foldable}>
              {label}
            </Table2ColCellStyle>
            <Table2ColCellStyle foldable={foldable} size={size}>
              {value}
            </Table2ColCellStyle>
          </TableTopCellContainerStyle>
          {foldingContent && (
            <TableFoldingIconStyle unfolded={unfolded}>
              <Icon name={IconName.ChevronRight} styleType={Style.Inverted} />
            </TableFoldingIconStyle>
          )}
        </Table2ColTopWrapStyle>
        {foldingContent && (
          <Transition in={unfolded} timeout={0}>
            {(status) => (
              <Table2ColCellContentStyle animationStatus={status}>
                {foldingContent}
              </Table2ColCellContentStyle>
            )}
          </Transition>
        )}
      </Table2ColRowStyle>
    );
  }
}

TableRow.defaultProps = {
  size: 'default',
};

TableRow.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  size: PropTypes.oneOf(['default', 'small']),
};

class Table2Col extends React.PureComponent {
  getRows() {
    const { labels, size, foldingContent, values } = this.props;
    const foldable = !!foldingContent;
    return labels.map((label, i) => {
      const foldingContentNew = Array.isArray(foldingContent) ? foldingContent[i] : null;
      return (
        <TableRow
          size={size}
          label={label}
          value={values[i]}
          tabIndex={foldable ? i : null}
          key={`tableRow${i}`}
          foldingContent={foldingContentNew}
        />
      );
    });
  }

  render() {
    return <Table2ColStyle>{this.getRows()}</Table2ColStyle>;
  }
}

Table2Col.defaultProps = {
  size: TableRow.defaultProps.size,
  foldingContent: null,
  // labels: [],
  // values: []
  labels: ['American Express', 'American Express', 'American Express', 'American Express'],
  values: ['$234,234', '$234,234', '$234,234', '$234,234'],
};

export default Table2Col;
