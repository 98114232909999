import styled from 'styled-components';
import {
  DropdownStyles,
  LinkButtonStyles,
  Modal,
  ModalStyles,
  SplashTextStyles,
} from '@pointdotcom/pds';
import {
  Table2ColCellContentStyle,
  Table2ColCellStyle,
  Table2ColRowStyle,
  Table2ColStyle,
  TableTopCellContainerStyle,
} from 'components/Table2Col/styles';
import { pxToRem } from 'styleHelpers';

export const TableLabelStyle = styled.div.attrs({ className: 'TableLabelStyle' })`
  display: grid;
  align-items: center;
  justify-content: center;
  h3 {
    font-weight: normal;
    text-transform: uppercase;
    font-size: ${pxToRem(14)};
    margin: 0;
  }
  @media (max-width: ${({ theme }) => theme.responsive.largestMobileScreen}) {
    output {
      display: none;
    }
  }
`;

export const PreviewModalStyle = styled(Modal).attrs({
  className: 'PreviewModalContentStyle',
})`
  --left-right-padding: 5rem;
  ${DropdownStyles.DropDownMainControlContainerStyle} {
    label {
      font-size: ${({ theme }) => theme.header.fontSize.splash2.default};
    }
  }
  ${ModalStyles.ModalContainerInnerStyle} {
    padding: 0;
    > header {
      display: grid;
      gap: 2rem;
      padding: 1.5rem var(--left-right-padding) 3rem;
    }
    > footer {
      display: grid;
      gap: 1.5rem;
      align-items: center;
      justify-content: center;
      padding: 3rem var(--left-right-padding) 6rem;
    }
  }
  ${LinkButtonStyles.ButtonLinkStyle} {
    color: ${({ theme }) => theme.Color.GrayLightestAccessible};
  }
  ${SplashTextStyles.SplashCopySerifStyle} {
    color: ${({ theme }) => theme.Color.GrayLightestAccessible};
    font-size: 20px;
    @media (max-width: ${({ theme }) => theme.responsive.largestMobileScreen}) {
      font-size: 16px;
    }
  }
  ${Table2ColStyle} {
    output {
      text-align: center;
      font-weight: ${({ theme }) => theme.header.fontWeight.default};
      font-size: ${({ theme }) => theme.header.fontSize.default.default};
    }
    path {
      fill: ${({ theme }) => theme.line.color.default};
    }
    border-bottom: 1px solid ${({ theme }) => theme.line.color.default};
    ${TableTopCellContainerStyle} {
      align-items: stretch;
      ${Table2ColCellStyle} {
        font-weight: normal;
        display: flex;
        &:first-child {
          border-right: 1px solid ${({ theme }) => theme.line.color.default};
          justify-content: center;
          flex: 1;
        }
        &:last-child {
          padding-left: 2rem;
          flex: 2.5;
        }
      }
    }
    ${Table2ColRowStyle} {
      padding: 3rem var(--left-right-padding);
      border-top: 1px solid ${({ theme }) => theme.line.color.default};
      &:before {
        display: none;
      }
    }
    ${Table2ColCellContentStyle} {
      padding-bottom: 0;
      margin: 0;
    }
  }
  @media (max-width: ${({ theme }) => theme.responsive.largestMobileScreen}) {
    --left-right-padding: 10vw;
    ${DropdownStyles.DropDownMainControlContainerStyle} {
      label {
        font-size: ${pxToRem(64)};
      }
    }
    ${Table2ColStyle} {
      output {
        font-size: ${({ theme }) => theme.header.fontSize.default.mobile};
      }
      ${Table2ColRowStyle} {
        padding-block: 2rem;
      }
      ${TableTopCellContainerStyle} {
        ${Table2ColCellStyle} {
          &:first-child {
            justify-content: flex-start;
            flex: 3;
            border-right: none;
            margin-bottom: 0;
          }
          &:last-child {
            justify-content: flex-end;
            padding-left: 0;
            padding-right: 2rem;
            flex: 2;
            border-right: 1px solid ${({ theme }) => theme.line.color.default};
          }
        }
      }
    }
  }
`;
