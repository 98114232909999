import React from 'react';
import ReactDOM from 'react-dom';
import Helmet from 'react-helmet';
import { Transition, TransitionGroup } from 'react-transition-group';
import { DirectionAndPlacement, Header, Size, SplashText } from '@pointdotcom/pds';
import { ApplicationErrorContent } from 'components/ApplicationError';
import MainHeaderNoNav from 'components/MainHeaderNoNav';
import i18n from './i18n';
import applicationImg from './images/application.png';
import applicationShineImg from './images/application_shine.png';
import * as styles from './styles';

const Graphic = () => (
  <styles.GraphicStyle>
    <div>
      <img src={applicationShineImg} className="applicationShineImg" alt="application shine icon" />
      <img src={applicationImg} className="applicationImg" alt="application icon" />
    </div>
  </styles.GraphicStyle>
);

const ApplicationLoadingContents = ({
  animationStatus,
  subHeaderText,
  headerText,
  headerProps,
  splashTextProps,
  modal,
}) => (
  <styles.ApplicationLoadingStyle
    modal={modal}
    role={modal ? 'dialog' : null}
    animationStatus={animationStatus}
  >
    <Graphic />
    <Header
      styleSize={Size.Large}
      maxWidth="280px"
      styleAlign={DirectionAndPlacement.Center}
      {...headerProps}
    >
      {headerText}
    </Header>
    <SplashText styleAlign={DirectionAndPlacement.Center} {...splashTextProps}>
      {subHeaderText}
    </SplashText>
  </styles.ApplicationLoadingStyle>
);

const ApplicationLoading = ({
  show = false,
  modal = true,
  headerText = i18n.creatingYour,
  subHeaderText = i18n.thisMay,
  headerProps = {},
  splashTextProps = {},
}) => {
  const root = document.getElementById('root') || document.body;
  return modal && root ? (
    ReactDOM.createPortal(
      <TransitionGroup>
        {show && (
          <Transition in={show} timeout={{ enter: 0, exit: styles.ANIM_SPEED_MS }}>
            {(animationStatus) => (
              <ApplicationLoadingContents
                animationStatus={animationStatus}
                subHeaderText={subHeaderText}
                headerText={headerText}
                headerProps={headerProps}
                splashTextProps={splashTextProps}
                modal={modal}
              />
            )}
          </Transition>
        )}
      </TransitionGroup>,
      root
    )
  ) : (
    <ApplicationLoadingContents
      subHeaderText={subHeaderText}
      headerText={headerText}
      headerProps={headerProps}
      splashTextProps={splashTextProps}
      modal={modal}
    />
  );
};

const ApplicationLoadingPage = ({
  loading = false,
  error = false,
  errorHeaderText,
  errorParagraphText,
  headerUrl,
  maxWidth = '380px',
  title = i18n.yourApplication,
  headerText,
  subHeaderText,
}) => {
  return (
    <styles.ApplicationPageStyle>
      <Helmet title={title} />
      <MainHeaderNoNav logoHref={error ? headerUrl : null} />
      <styles.ApplicationPageHeroStyle>
        {loading && !error ? (
          <ApplicationLoading
            modal={false}
            headerProps={{ styleSize: Size.Splash, maxWidth }}
            headerText={headerText}
            subHeaderText={subHeaderText}
          />
        ) : null}
        {error && !loading ? (
          <ApplicationErrorContent
            headerText={errorHeaderText}
            paragraphText={errorParagraphText}
          />
        ) : null}
      </styles.ApplicationPageHeroStyle>
    </styles.ApplicationPageStyle>
  );
};

export default ApplicationLoading;
export { ApplicationLoadingPage };
